export const MtlbType = {
	"COMPLAINT": 1
	
}
Object.freeze(MtlbType);
const toTypeMap = {};
Object.keys(MtlbType).forEach(key => {
	toTypeMap[MtlbType[key]] = key;
});
export function getMtlbType (value) {
	return toTypeMap[value];
}

export function getAllMtlbType () {
    return Object.keys(MtlbType);
}

export const MTLB_TYPE_COMPLAINT = "COMPLAINT";
