import React, { useState } from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_ADVANCED_SEARCH } from "../../../../auto/js/events/Gui";
import { getServiceUri, pojoMetadata } from "../../../../auto/js/metadata";
import { getMtlbStatus, MtlbStatus, MTLB_STATUS_ARCHIVED, MTLB_STATUS_MANUAL_CHECK, MTLB_STATUS_READY_FOR_APPROVAL, MTLB_STATUS_REJECTED, MTLB_STATUS_SUBMITTED } from "../../../../auto/js/metadata/MtlbStatus";
import { getMtlbType, MtlbType } from "../../../../auto/js/metadata/MtlbType";
import { rest, t } from "../../../../auto/js/services";
import { createTableComponent } from "../../../../auto/js/widgets/TableComponent"
import { complaintMtlbListColumns, getData, getMtlbTypeList, getOrderBy, countData, filterData, getErrorList, transformAdvancedSearchData, advancedSearchfields } from "../../../../main/js/lists/complaintMtlb/ComplaintMtlbListCommon"
import { Checkbox, FormControlLabel } from "@mui/material";
import Facets from "../../widgets/Facets";
import { displayReadApprovedComplaintMtlbForm } from "../../../../auto/js/forms/complaintMtlb/ApprovedComplaintMtlbForm";
import { displayReadPendingComplaintMtlbForm } from "../../forms/complaintMtlb/PendingComplaintMtlbForm";
import { displayReadReadyComplaintMtlbForm } from "../../forms/complaintMtlb/ReadyComplaintMtlbForm";
import { displayReadRejectedComplaintMtlbForm } from "../../forms/complaintMtlb/RejectedComplaintMtlbForm";


const ComplaintMtlbListPage = ({uuid, gui, searchData}) => {
    const [submitted, setSubmitted] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const[selectAllStatus, setSelectAllStatus] = useState(false);
    const[selectAllError, setSelectAllError] = useState(false);
    const [advancedSearchData, setAdvancedSearchData] = useState(searchData);

    let ComplaintMtlbTable = createTableComponent(complaintMtlbListColumns);

    const onFacetChange = (key, value) => {
        switch(key) {
            case ("submitted"):
                setSubmitted(value.target.checked);
                break;
            case ("selectAllStatus"):
            	setSelectAllStatus(value.target.checked);
            	setSubmitted(value.target.checked);
				break;
        }
    }
    
    if (advancedSearchData != searchData)
		setAdvancedSearchData(searchData);
    
    const buildData = async (query) => {
        let filter = query;
        let data;
        let mtlbStatusList = [];
        let tags =[];
        if (submitted)
            mtlbStatusList.push(MTLB_STATUS_SUBMITTED);
        if (!submitted )
            mtlbStatusList = [MTLB_STATUS_SUBMITTED]
        errorList.forEach(element => {
            tags.push( {content: element} )
        });
        filter["complaint-mtlb-document"] = {mtlbTypeList: getMtlbTypeList(), mtlbStatusList: mtlbStatusList};
        filter['and'] = true;
        filter["orderBy"] = getOrderBy();
        if (advancedSearchData)
            filter["query"] = advancedSearchData;
        filter.orderDirection = null;
        filter.offset = query.page * query.pageSize;
        if (query.search && query.search!='') {
            pojoMetadata["complaint-mtlb-document"].columns.forEach(element => {
                if(element.type=='text' ){
                    filter["complaint-mtlb-document"][element.key]= query.search;
                }
            });
            filter['and'] = false;
            filter.fuzziness = "AUTO";
        }
        return await getData(filter).then(response => {
            data = filterData(response)
            return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
        });
    }
    
    const onErrorFacetChange = (key, value) => {
        let list = _.clone(errorList);
        if (key === "selectAllError") {
            if (value.target.checked)
                list = selectAllErrors();
            else
                list = [];
            setErrorList(list);
            setSelectAllError(value.target.checked)
        }
        if (value.target.checked && !list.includes(key)) {
            list.push(key);
            setErrorList(list);
        }
        else if (!value.target.checked && list.includes(key)) {
            let index = list.indexOf(key);
            list.splice(index, 1);
            setErrorList(list);
        }
    }
    
    const getErrorCodeFacets = () => {
      let list = [{key: "selectAllError", value: selectAllError, label: t`select/deselect all`, separator: true}];
        getErrorList().forEach(error => {
            list.push({key: error, value: errorList.includes(error), label: t(error)});

        });
        return list;
    }
    
    const onAdvancedSearchSubmit = (data) => {
        let dto = transformAdvancedSearchData(data);
        setAdvancedSearchData(dto);
    }
    
    const buildFacets = (key, value, label, handleChange) => {
        return (
            <>
            <FormControlLabel
            control={<Checkbox
              checked={value}
              onChange={(event) => handleChange(key, event)}
              color="primary"
              value={value}
            />
            }
            label={label}
            style={{width: '100%'}}
            />
            </>
        )
    }

    return (
        <div>
            <Facets title={t`Complaint Status`} facets={[{key: "selectAllStatus", value: selectAllStatus, separator: true}, { key: "submitted", value: submitted }]} facetsComponents={(key, value) => buildFacets(key, value, t(key), onFacetChange)} />
            <Facets title={t`Errors`} facets={getErrorCodeFacets()} facetsComponents={(key, value) => buildFacets(key, value, t(key), onErrorFacetChange)} />
            <ComplaintMtlbTable key={uuid} loadData={async (query) => buildData(query)}  actions={getTableActions(gui)} advancedSearchFields={advancedSearchfields} onSubmit={onAdvancedSearchSubmit}/>
    	</div>
    )


}

export const displayComplaintMtlbList = (gui,advancedSearchData) => {  
    const readComplaintMtlbList = () => {
    let uuid = uuidv4();
		return {
			uuid, view: () => <ComplaintMtlbListPage gui={gui} key={uuid}  searchData={advancedSearchData}/>
		};
	}
	gui.goTo(readComplaintMtlbList)
}

export const displayComplaintMtlbAdvancedSearch = (gui) => {
    let advancedSearchFilters = {fields: advancedSearchfields, name: t`ComplaintMtlb`, onSubmit: (filter) => onAdvancedSearchSubmit(filter, gui)};
	OPEN_ADVANCED_SEARCH.publish(advancedSearchFilters);
}

const onAdvancedSearchSubmit = (filter, gui) => {
    displayComplaintMtlbList(gui, transformAdvancedSearchData(filter));
}

const selectAllErrors = () => {
    let list = ["selectAllError"];
    getErrorList().forEach(error => {
            list.push(error);
    });
    return list;
}

const getTableActions = (gui) => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
                tooltip: t`Edit`,
                onClick: (event, rowData) => {
                    switch(rowData.mtlbStatus) {
                         case (MTLB_STATUS_SUBMITTED):
                        	gui.goTo(displayReadApprovedComplaintMtlbForm(), rowData.id, null);
                            break;
                    }
                }
        }
    )
    return actions;   
}
