export const pojoMetadata = {
			'complaint-mtlb': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "complainantAddress", name: "complainant_address", editable: true, resizable: true, type: "text" },
			   	{ key: "complainantContactPreferences", name: "complainant_contact_preferences", editable: true, resizable: true, type: "number" },
			   	{ key: "complainantEmailAddress", name: "complainant_email_address", editable: true, resizable: true, type: "text" },
			   	{ key: "complainantFirstName", name: "complainant_first_name", editable: true, resizable: true, type: "text" },
			   	{ key: "complainantIdCardNumber", name: "complainant_id_card_number", editable: true, resizable: true, type: "number" },
			   	{ key: "complainantLastName", name: "complainant_last_name", editable: true, resizable: true, type: "text" },
			   	{ key: "complainantPhoneNumber", name: "complainant_phone_number", editable: true, resizable: true, type: "text" },
			   	{ key: "complaintRecordId", name: "complaint_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "description", name: "description", editable: true, resizable: true, type: "text" },
			   	{ key: "desiredOutcome", name: "desired_outcome", editable: true, resizable: true, type: "text" },
			   	{ key: "evidence", name: "evidence", editable: true, resizable: true, type: "text" },
			   	{ key: "incidentCategory", name: "incident_category", editable: true, resizable: true, type: "number" },
			   	{ key: "incidentDate", name: "incident_date", editable: true, resizable: true, type: "date" },
			   	{ key: "incidentTime", name: "incident_time", editable: true, resizable: true, type: "time" },
			   	{ key: "jurisdiction", name: "jurisdiction", editable: true, resizable: true, type: "text" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "subjectAddress", name: "subject_address", editable: true, resizable: true, type: "text" },
			   	{ key: "subjectOfComplaint", name: "subject_of_complaint", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.complainantAddress == "")
					data.complainantAddress = null;
				if(data.complainantEmailAddress == "")
					data.complainantEmailAddress = null;
				if(data.complainantFirstName == "")
					data.complainantFirstName = null;
				if(data.complainantLastName == "")
					data.complainantLastName = null;
				if(data.complainantPhoneNumber == "")
					data.complainantPhoneNumber = null;
				if(data.description == "")
					data.description = null;
				if(data.desiredOutcome == "")
					data.desiredOutcome = null;
				if(data.incidentDate != null && data.incidentDate instanceof Date)
					data.incidentDate = data.incidentDate.getFullYear() + "-" + ('0' + (data.incidentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.incidentDate.getDate()).slice(-2);
				if(data.jurisdiction == "")
					data.jurisdiction = null;
				if(data.subjectAddress == "")
					data.subjectAddress = null;
				if(data.subjectOfComplaint == "")
					data.subjectOfComplaint = null;
				return data
			}
		},
		'complaint-record': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "complainantAddress", name: "complainant_address", editable: true, resizable: true, type: "text" },
			   	{ key: "complainantContactPreferences", name: "complainant_contact_preferences", editable: true, resizable: true, type: "number" },
			   	{ key: "complainantEmailAddress", name: "complainant_email_address", editable: true, resizable: true, type: "text" },
			   	{ key: "complainantFirstName", name: "complainant_first_name", editable: true, resizable: true, type: "text" },
			   	{ key: "complainantIdCardNumber", name: "complainant_id_card_number", editable: true, resizable: true, type: "number" },
			   	{ key: "complainantLastName", name: "complainant_last_name", editable: true, resizable: true, type: "text" },
			   	{ key: "complainantPhoneNumber", name: "complainant_phone_number", editable: true, resizable: true, type: "text" },
			   	{ key: "description", name: "description", editable: true, resizable: true, type: "text" },
			   	{ key: "desiredOutcome", name: "desired_outcome", editable: true, resizable: true, type: "text" },
			   	{ key: "evidence", name: "evidence", editable: true, resizable: true, type: "text" },
			   	{ key: "incidentCategory", name: "incident_category", editable: true, resizable: true, type: "number" },
			   	{ key: "incidentDate", name: "incident_date", editable: true, resizable: true, type: "date" },
			   	{ key: "incidentTime", name: "incident_time", editable: true, resizable: true, type: "time" },
			   	{ key: "jurisdiction", name: "jurisdiction", editable: true, resizable: true, type: "text" },
			   	{ key: "subjectAddress", name: "subject_address", editable: true, resizable: true, type: "text" },
			   	{ key: "subjectOfComplaint", name: "subject_of_complaint", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.complainantAddress == "")
					data.complainantAddress = null;
				if(data.complainantEmailAddress == "")
					data.complainantEmailAddress = null;
				if(data.complainantFirstName == "")
					data.complainantFirstName = null;
				if(data.complainantLastName == "")
					data.complainantLastName = null;
				if(data.complainantPhoneNumber == "")
					data.complainantPhoneNumber = null;
				if(data.description == "")
					data.description = null;
				if(data.desiredOutcome == "")
					data.desiredOutcome = null;
				if(data.incidentDate != null && data.incidentDate instanceof Date)
					data.incidentDate = data.incidentDate.getFullYear() + "-" + ('0' + (data.incidentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.incidentDate.getDate()).slice(-2);
				if(data.jurisdiction == "")
					data.jurisdiction = null;
				if(data.subjectAddress == "")
					data.subjectAddress = null;
				if(data.subjectOfComplaint == "")
					data.subjectOfComplaint = null;
				return data
			}
		},
		'geo-data': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "areaId", name: "area_id", editable: true, resizable: true, type: "text" },
			   	{ key: "disabled", name: "disabled", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "flag", name: "flag", editable: true, resizable: true, type: "blob" },
			   	{ key: "flagMimeType", name: "flag_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "parentId", name: "parent_id", editable: true, resizable: true, type: "number" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.areaId == "")
					data.areaId = null;
				if(data.flagMimeType == "")
					data.flagMimeType = null;
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'complaint-mtlb-tag': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "complaintMtlbId", name: "complaint_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'i18n': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "domainId", name: "domain_id", editable: true, resizable: true, type: "text" },
			   	{ key: "localeId", name: "locale_id", editable: true, resizable: true, type: "text" },
			   	{ key: "messageId", name: "message_id", editable: true, resizable: true, type: "text" },
			   	{ key: "value", name: "value", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.domainId == "")
					data.domainId = null;
				if(data.localeId == "")
					data.localeId = null;
				if(data.messageId == "")
					data.messageId = null;
				if(data.value == "")
					data.value = null;
				return data
			}
		},
		'user-preferences': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			   	{ key: "value", name: "value", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.key == "")
					data.key = null;
				if(data.userId == "")
					data.userId = null;
				if(data.value == "")
					data.value = null;
				return data
			}
		},
}

export const buildEmptyObject = (entity) => {
	var columns = new Object();
	let fields = pojoMetadata[entity].columns
	for (let col in fields) {		
		if(fields[col].type == 'text'){
			columns[fields[col].key] = "";
		}else if(fields[col].type=='checkbox'){
			columns[fields[col].key] = false;
		}else if(fields[col].type=='datetime-local'){
			columns[fields[col].key] = "";
		}
		else if(fields[col].type=='autocomplete'){
			columns[fields[col].key] = null;
		}
	 }
	return columns
};

export const getEntityIdCol = (entity) => {
	return pojoMetadata[entity].columns[0].key;
}

export const getRowId = (entity, row) => {
	return row[getEntityIdCol(entity)];
}

