import React from "react";
import {v4 as uuidv4} from 'uuid';

import { createFormComponent } from '../../widgets/FormComponent';
import { OPEN_VIEW_EVENT, CAN_I_SWITCH, DELETE_ATTACHMENT_EVENT, FILE_UPLOADED_EVENT } from '../../events/Gui';
import { AlertDialog } from "../../widgets";
import { rest } from "../../services/RestClient";
import { t } from "../../services/i18ndb";
import { showNotification } from "../../utils";
import { getServiceUri } from "../../metadata";
import { updateRejectedComplaintMtlbForm, loadComplaintMtlbFormData } from "./ComplaintMtlbApi";
import { complaintMtlbFields } from "../../../../main/js/forms/complaintMtlb/ComplaintMtlbFormCommon";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FileInput } from "../../widgets/FileInput";
import { Section } from "../../../../auto/js/widgets/Section";
import { createTableComponent } from "../../widgets/TableComponent";
		
let ComplaintMtlbForm = createFormComponent(complaintMtlbFields);

class FormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
    }

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = () => {
		this.state.closeRequested(true);
	}

	render() {
		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={this.handleDontSave}
			/>
			<ComplaintMtlbForm ref={this.myRef} key={this.props.key} loadData={async () => loadFormData(this.props.id)} onSubmit={(this.props.onFinish)?(data) => update(data).then(() => this.props.onFinish()):update} id={this.props.id} buttons={() => getButtons(this.props.id)} />
		</>
		)
	}

}


export const displayReadRejectedComplaintMtlbForm = (onFinish) => (id) => {
	let uuid = uuidv4();
	return {
		uuid, view: () => <FormComponent key={uuid} id={id} onFinish={onFinish}/>
	};
}

const update = async (formData) => {
	return updateRejectedComplaintMtlbForm(formData);
}

const loadFormData = async (id) => {
	return await loadComplaintMtlbFormData(id);
}

const getButtons = (id) => {
	return (
    <>
        <button style={{ minWidth: '5rem' }} type="submit"> {t`Reopen`} </button>
    </>
    )
}
